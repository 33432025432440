import React from "react";
import { Link } from 'gatsby'
import { FaChevronRight } from 'react-icons/fa'
import Layout from "../components/layout";
import SEO from "../components/seo";




function NotFoundPage() {
  return (
    <Layout>
      <SEO
        keywords={[`UX`, `Freelance`, `Designer`, `Researcher`]}
        title="404: Not found"
      />
      <div className="text-center">
        <h2 className="text-2xl font-bold my-5 p-3">
          Thank you for your submisssion
        </h2>
        <Link className="underline font-semibold hover:font-bold" to="/">
          <button className="hover:bg-teal-600 bg-gray-900 text-white font-bold py-2 px-4 rounded-full">
            Return home <FaChevronRight className="inline" />
          </button>
        </Link>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
